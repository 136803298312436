.navbar{
  position: fixed;
  top: 0;
  height: 4rem;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #07001F;
}
.navbar a{
  margin: 0 3rem;
  color: rgba(255,255,255,0.85);
  text-decoration: none;
}
.navbar button{
  border: none;
  background-color: #391CD5;
  color: rgba(255,255,255,0.85);
  padding:12px 20px;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 2px;
  margin-left: 2rem;
}
.iconContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}
.iconContainer img{
  width: 7vw;
}
footer .iconContainer{
  margin-top: 1rem;
}
footer .iconContainer img{
  width: 7vw;
}
.compassContainer{
  position: absolute;
  top: 15vh;
  left: 10vw;
  width: 80vw;
  z-index: -1;
}
.titleContainer{
  margin-top: 5vw;
  width: 100vw;
}
.titleContainer h1{
  color: rgba(255,255,255,0.85);
  font-weight: 500;
  font-size: 2.2rem;
  text-align: center;
}
.currencyContainer{
  opacity: 1;
}
.euroContainer{
  position: absolute;
  top: 35vw;
  left: 7vw;
  width: 10vw;
}
.usdContainer{
  position: absolute;
  top: 40vw;
  left: 32vw;
  width: 10vw;
}
.yuanContainer{
  position: absolute;
  top: 32vw;
  left: 52vw;
  width: 10vw;
}
.lumenContainer{
  position: absolute;
  top: 40vw;
  left: 80vw;
  width: 10vw;
}
.poundContainer{
  position: absolute;
  top: 50vw;
  left: 62vw;
  width: 10vw;
}
.heavyStarsContainer{
  position: absolute;
  top: 0vw;
  width: 100vw;
  z-index: -1;
  width: 95vw;
}
.sparseStarsContainer{
  position: absolute;
  top: 0vw;
  width: 100vw;
  z-index: -1;
  width: 95vw;
}
.subTitleContainer{
  margin-top: 40vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subTitleContainer h1{
  color: rgba(255,255,255,0.85);
  font-weight: 500;
  font-size: 2.2rem;
  text-align: center;
}
.subTitleContainer p{
  color: rgba(255,255,255,0.85);
  font-weight: 400;
  font-size: 0.95rem;
  text-align: center;
  margin-bottom: 0.5rem;
}
.subTitleContainer button{
  background-color: #391CD5;
  border: none;
  padding:15px 30px;
  font-size: 0.90rem;
  font-weight: 600;
  color:rgba(255,255,255,0.75);
  border-radius: 3px;
  margin-top: 3vw
}
.sectionTitle{
  display: flex;
  flex-direction: column;
  width: 90vw;
  /* border: 1px solid white; */
  margin-top: 15vw;
  margin-bottom: 15vw;
  margin-left: 5vw;
}
.sectionTitle h1{
  color: rgba(255,255,255,0.85);
  font-weight: 500;
  font-size: 2.2rem;
  text-align: center;
}
.sectionTitle p{
  color: rgba(255,255,255,0.85);
  text-align: center;
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}
.phoneContainer{
  width: 24vw;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 20px;
  background-color: black;
  border: 2px solid rgba(200,200,200,0.5);
}
.phoneContainer img{
  width: 20vw;
}
.section{
  /* border: 1px solid white; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.section .text{
  margin-left: 5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.section h2{
  color: rgba(255,255,255,0.85);
}
.section p{
  color: rgba(255,255,255,0.85);
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
}
.devTitle{
  width: 90vw;
  margin-left: 5vw;
}
.frameContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  width: 100vw;
  /* border: 1px solid white; */
  margin-top: 20vw;
}
.frameContainer .devTitle{
  text-align: center;
  color: rgba(255,255,255,0.85);
}
.devTitle h1{
  font-size: 1.5rem;
}
.devTitle p{
  font-size: 0.9rem;
}
.frameContainer button{
  background-color: #391CD5;
  border: none;
  padding:15px 30px;
  font-size: 0.90rem;
  font-weight: 600;
  color:rgba(255,255,255,0.75);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 1vw;
  margin-bottom: 3vw;
}
.frameContainer .options{
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: center;
}
.frameContainer .options button{
  border:none;
  cursor: pointer;
  font-size: 0.95rem;
  border-radius: 4px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 0.90rem;
  width: 180px;
}
.frame{
  width: 80vw;
  height: 40vw;
  border-width: 0;
  margin-top: 2rem;
}
.sponsorContainer{
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15vw;
}
.sponsorContainer .title{
  text-align: center;
  color: rgba(255,255,255,0.85);
  width: 90vw;
}
.sponsorContainer .title h1{
  font-size: 1.5rem;
}
.sponsorContainer .title p{
  font-size: 0.9rem;
}
.sponsorContainer button{
  background-color: #391CD5;
  border: none;
  padding:15px 30px;
  font-size: 0.90rem;
  font-weight: 600;
  color:rgba(255,255,255,0.75);
  border-radius: 3px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 15px;
}
.logoContainer{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  height: 8vw;
  margin-top: 2vw;
}
.logoContainer img{
  width: 15vw;
  filter: invert(0.75) grayscale(1);
  opacity: 0.4;
}
footer{
  margin-top: 10vw;
}
footer .links{
  display: flex;
  flex-direction: row;
  width: 80vw;
  margin: auto auto;
  justify-content: space-between;
}
.links img{
  width: 10vw;
  transform: scale(0.7);
}
footer .links .crypto{
  display: flex;
  flex-direction: column;
}
.crypto p{
  color: rgba(255,255,255,0.75);
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.crypto a{
  color: rgba(255,255,255,0.35);
  text-decoration: none;
  margin-bottom: 0.6rem;
  font-weight: 500;
}
footer .links .exchange{
  display: flex;
  flex-direction: column;
}
.exchange p{
  color: rgba(255,255,255,0.75);
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.exchange a{
  color: rgba(255,255,255,0.35);
  text-decoration: none;
  margin-bottom: 0.6rem;
  font-weight: 500;
}
footer .links .social{
  display: flex;
  flex-direction: column;
}
.social p{
  color: rgba(255,255,255,0.75);
  margin-bottom: 0.8rem;
  font-weight: 600;
}
.social a{
  color: rgba(255,255,255,0.35);
  text-decoration: none;
  margin-bottom: 0.6rem;
  font-weight: 500;
}
.copyright{
  margin-top: 10rem;
  border-top: 0.5px solid rgba(255,255,255,0.15);
}
.copyright p{
  color: rgba(255,255,255,0.75);
  text-align: center;
  font-size: 0.8rem;
}

@media screen and (max-width: 575px) {
  p{
    line-height: 2rem;
  }
  .navbar{
    display: none;
  }
  .iconContainer{
    margin-top: 2rem;
  }
  .titleContainer{
    margin-top: 2rem;
  }
  .titleContainer h1{
    font-size: 1.5rem;
  }
  .subTitleContainer{
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin-left: 5vw;
  }
  .subTitleContainer h1{
    font-size: 1.5rem;
  }
  .subTitleContainer p{
    font-size: 0.9rem;
  }
  .sectionTitle h1{
    font-size: 1.5rem;
  }
  .sectionTitle p{
    font-size: 0.9rem;
  }
  .section{
    display: flex;
    flex-direction: column;
  }
  .section .phoneContainer{
    width: 48vw;
  }
  .phoneContainer img{
    width: 40vw
  }
  .section .text{
    margin-left: 0;
    margin-top: 2rem;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
  .section .text h2{
    font-size: 1.2rem;
  }
  .section .text p{
    font-size: 0.9rem;
  }
  .btnAndFrames .options{
    display: none;
  }
  .frame{
    width: 100vw;
    height: 70vw;
  }
  footer .links{
    width: 85vw;
  }
  footer .links .iconContainer{
    display: none;
  }
  .crypto p{
    font-size: 0.9rem;
  }
  .crypto a{
    font-size: 0.8rem;
  }
  .exchange p{
    font-size: 0.9rem;
  }
  .exchange a{
    font-size: 0.8rem;
  }
  .social p{
    font-size: 0.9rem;
  }
  .social a{
    font-size: 0.8rem;
  }
  .copyright{
    width: 95vw;
    margin: 0 auto;
    margin-top: 5rem;
  }
  .copyright p{
    font-size: 0.5rem;
    line-height: 0.75rem;
    text-align: center;
  }
}